var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/button-has-type */
import * as React from 'react';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import Group, { GroupSizeContext } from './button-group';
import { ConfigContext } from '../config-provider';
import Wave from '../_util/wave';
import { tuple } from '../_util/type';
import devWarning from '../_util/devWarning';
import SizeContext from '../config-provider/SizeContext';
import LoadingIcon from './LoadingIcon';
import { cloneElement } from '../_util/reactNode';
const rxTwoCNChar = /^[\u4e00-\u9fa5]{2}$/;
const isTwoCNChar = rxTwoCNChar.test.bind(rxTwoCNChar);
function isString(str) {
    return typeof str === 'string';
}
function isUnBorderedButtonType(type) {
    return type === 'text' || type === 'link';
}
function isReactFragment(node) {
    return React.isValidElement(node) && node.type === React.Fragment;
}
// Insert one space between two chinese characters automatically.
function insertSpace(child, needInserted) {
    // Check the child if is undefined or null.
    if (child == null) {
        return;
    }
    const SPACE = needInserted ? ' ' : '';
    // strictNullChecks oops.
    if (typeof child !== 'string' &&
        typeof child !== 'number' &&
        isString(child.type) &&
        isTwoCNChar(child.props.children)) {
        return cloneElement(child, {
            children: child.props.children.split('').join(SPACE),
        });
    }
    if (typeof child === 'string') {
        return isTwoCNChar(child) ? <span>{child.split('').join(SPACE)}</span> : <span>{child}</span>;
    }
    if (isReactFragment(child)) {
        return <span>{child}</span>;
    }
    return child;
}
function spaceChildren(children, needInserted) {
    let isPrevChildPure = false;
    const childList = [];
    React.Children.forEach(children, child => {
        const type = typeof child;
        const isCurrentChildPure = type === 'string' || type === 'number';
        if (isPrevChildPure && isCurrentChildPure) {
            const lastIndex = childList.length - 1;
            const lastChild = childList[lastIndex];
            childList[lastIndex] = `${lastChild}${child}`;
        }
        else {
            childList.push(child);
        }
        isPrevChildPure = isCurrentChildPure;
    });
    // Pass to React.Children.map to auto fill key
    return React.Children.map(childList, child => insertSpace(child, needInserted));
}
const ButtonTypes = tuple('default', 'primary', 'ghost', 'dashed', 'link', 'text');
const ButtonShapes = tuple('default', 'circle', 'round');
const ButtonHTMLTypes = tuple('submit', 'button', 'reset');
export function convertLegacyProps(type) {
    if (type === 'danger') {
        return { danger: true };
    }
    return { type };
}
const InternalButton = (props, ref) => {
    const { loading = false, prefixCls: customizePrefixCls, type = 'default', danger, shape = 'default', size: customizeSize, className, children, icon, ghost = false, block = false, 
    /** If we extract items here, we don't need use omit.js */
    // React does not recognize the `htmlType` prop on a DOM element. Here we pick it out of `rest`.
    htmlType = 'button' } = props, rest = __rest(props, ["loading", "prefixCls", "type", "danger", "shape", "size", "className", "children", "icon", "ghost", "block", "htmlType"]);
    const size = React.useContext(SizeContext);
    const groupSize = React.useContext(GroupSizeContext);
    const [innerLoading, setLoading] = React.useState(!!loading);
    const [hasTwoCNChar, setHasTwoCNChar] = React.useState(false);
    const { getPrefixCls, autoInsertSpaceInButton, direction } = React.useContext(ConfigContext);
    const buttonRef = ref || React.createRef();
    const isNeedInserted = () => React.Children.count(children) === 1 && !icon && !isUnBorderedButtonType(type);
    const fixTwoCNChar = () => {
        // Fix for HOC usage like <FormatMessage />
        if (!buttonRef || !buttonRef.current || autoInsertSpaceInButton === false) {
            return;
        }
        const buttonText = buttonRef.current.textContent;
        if (isNeedInserted() && isTwoCNChar(buttonText)) {
            if (!hasTwoCNChar) {
                setHasTwoCNChar(true);
            }
        }
        else if (hasTwoCNChar) {
            setHasTwoCNChar(false);
        }
    };
    // =============== Update Loading ===============
    const loadingOrDelay = typeof loading === 'object' && loading.delay ? loading.delay || true : !!loading;
    React.useEffect(() => {
        let delayTimer = null;
        if (typeof loadingOrDelay === 'number') {
            delayTimer = window.setTimeout(() => {
                delayTimer = null;
                setLoading(loadingOrDelay);
            }, loadingOrDelay);
        }
        else {
            setLoading(loadingOrDelay);
        }
        return () => {
            if (delayTimer) {
                // in order to not perform a React state update on an unmounted component
                // and clear timer after 'loadingOrDelay' updated.
                window.clearTimeout(delayTimer);
                delayTimer = null;
            }
        };
    }, [loadingOrDelay]);
    React.useEffect(fixTwoCNChar, [buttonRef]);
    const handleClick = (e) => {
        const { onClick, disabled } = props;
        // https://github.com/ant-design/ant-design/issues/30207
        if (innerLoading || disabled) {
            e.preventDefault();
            return;
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    if (process.env.NODE_ENV !== 'production')
        devWarning(!(typeof icon === 'string' && icon.length > 2), 'Button', `\`icon\` is using ReactNode instead of string naming in v4. Please check \`${icon}\` at https://ant.design/components/icon`);
    devWarning(!(ghost && isUnBorderedButtonType(type)), 'Button', "`link` or `text` button can't be a `ghost` button.");
    const prefixCls = getPrefixCls('btn', customizePrefixCls);
    const autoInsertSpace = autoInsertSpaceInButton !== false;
    const sizeClassNameMap = { large: 'lg', small: 'sm', middle: undefined };
    const sizeFullname = groupSize || customizeSize || size;
    const sizeCls = sizeFullname ? sizeClassNameMap[sizeFullname] || '' : '';
    const iconType = innerLoading ? 'loading' : icon;
    const classes = classNames(prefixCls, {
        [`${prefixCls}-${shape}`]: shape !== 'default' && shape,
        [`${prefixCls}-${type}`]: type,
        [`${prefixCls}-${sizeCls}`]: sizeCls,
        [`${prefixCls}-icon-only`]: !children && children !== 0 && !!iconType,
        [`${prefixCls}-background-ghost`]: ghost && !isUnBorderedButtonType(type),
        [`${prefixCls}-loading`]: innerLoading,
        [`${prefixCls}-two-chinese-chars`]: hasTwoCNChar && autoInsertSpace,
        [`${prefixCls}-block`]: block,
        [`${prefixCls}-dangerous`]: !!danger,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    const iconNode = icon && !innerLoading ? (icon) : (<LoadingIcon existIcon={!!icon} prefixCls={prefixCls} loading={!!innerLoading}/>);
    const kids = children || children === 0
        ? spaceChildren(children, isNeedInserted() && autoInsertSpace)
        : null;
    const linkButtonRestProps = omit(rest, ['navigate']);
    if (linkButtonRestProps.href !== undefined) {
        return (<a {...linkButtonRestProps} className={classes} onClick={handleClick} ref={buttonRef}>
        {iconNode}
        {kids}
      </a>);
    }
    const buttonNode = (<button {...rest} type={htmlType} className={classes} onClick={handleClick} ref={buttonRef}>
      {iconNode}
      {kids}
    </button>);
    if (isUnBorderedButtonType(type)) {
        return buttonNode;
    }
    return <Wave disabled={!!innerLoading}>{buttonNode}</Wave>;
};
const Button = React.forwardRef(InternalButton);
Button.displayName = 'Button';
Button.Group = Group;
Button.__ANT_BUTTON = true;
export default Button;
