import devWarning, { resetWarned } from 'rc-util/lib/warning';
export { resetWarned };
export default (valid, component, message) => {
    if (process.env.NODE_ENV !== 'production')
        devWarning(valid, `[antd: ${component}] ${message}`);
    // StrictMode will inject console which will not throw warning in React 17.
    if (process.env.NODE_ENV === 'test') {
        resetWarned();
    }
};
